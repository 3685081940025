










import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { Route, NavigationGuardNext } from "vue-router";

import PlacementForm from "@/settings/components/placements/PlacementForm.vue";
import PlacementRequestModel from "@/settings/models/placements/PlacementRequestModel";
import PlacementResponseModel from "@/settings/models/placements/PlacementResponseModel";
import NetworkIntegrationApplicationResponseModel from "@/settings/models/placements/NetworkIntegrationApplicationResponseModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    PlacementForm,
  },
})
export default class NewPlacementView extends mixins(UnsavedChangesMixin) {
  @Prop() placementId?: string;

  localPlacement = PlacementRequestModel.ofSource({
    waterfallId: "max_prod",
  } as PlacementRequestModel);

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localPlacement);
  }

  get networkIntegrationApplicationItems(): Array<NetworkIntegrationApplicationResponseModel> {
    return this.$store.state.placementsStore.networkIntegrationApplications;
  }

  @Watch("placementId", { immediate: true })
  private watchPlacementId(value: string) {
    if (!value) {
      this.localPlacement.waterfallId = "max_prod";

      return;
    }

    const copiedPlacement: PlacementResponseModel =
      this.$store.state.placementsStore.placements.find(
        (placement: PlacementResponseModel) =>
          placement.id && placement.id.toString() === value
      );

    this.localPlacement = PlacementRequestModel.ofSource({
      ...copiedPlacement,
      lat: null,
    });
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.placementManagement.newPlacementTitle")
    );
    this.handleWatchingUnsavedChanges();
  }

  mounted() {
    if (this.$route.query.networkNames) {
      this.localPlacement.networkIntegrationApplicationId =
        this.networkIntegrationApplicationItems.find(
          (item) => item.networkName === this.$route.query.networkNames[0]
        )?.id ?? 0;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async handleSave(payload: PlacementRequestModel) {
    await this.$store.dispatch("createPlacement", payload);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.PLACEMENTS,
      query: this.$route.query,
    });
  }
}
